import { useState } from 'react';
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import background_test from './background_test.png';

import Web3 from 'web3';

function App() {

  const [address, setAddress] = useState('');
  const [addressErr, setAddressErr] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    console.log("In here")
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const connectToWallet = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        // Request account access if needed
        await window.ethereum.enable();
        const getAddress = await web3.eth.getAccounts();

        if (getAddress.length > 0) {
          const address = getAddress[0];
          setAddress(address);
          setAddressErr('')
        }
        return web3;
      } catch (error) {
        console.error(error);
      }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      // Use Mist/MetaMask's provider.
      const web3 = window.web3;
      console.log('Injected web3 detected.');
      return web3;
    }
    // Fallback to localhost; use dev console port by default...
    else {
      const provider = new Web3.providers.HttpProvider('http://127.0.0.1:9545');
      const web3 = new Web3(provider);
      console.log('No web3 instance injected, using Local web3.');
      return web3;
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle>Mint</DialogTitle> */}
        <DialogContent style={{fontFamily: 'Ember'}}>
          Coming soon. . .
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <AppBar className='app-bar' position="static">
        <Toolbar>
          <a href='https://i.redd.it/d4900dbyiac71.jpg' target='_blank'><img src='images/logo.png' style={{width: '18%', cursor: 'hover'}}></img></a>
          <div className='right-side-content' style={{width: '30em', position: 'absolute', right: '0em', display: 'inherit', overflow: 'hidden'}}>
            <Button className='mint-btn' style={{position: 'relative', width: '100%', left: '10em', cursor: 'hover'}} onClick={() => setOpen(true)}>
              <img style={{width: '20%'}} src='images/mint.png'></img>
            </Button>
            <img src='images/cart.png' style={{width: '5em', height: '3em'}}></img>
          </div>

          {/* style={{color: 'white', backgroundColor: '#fe9900', position: 'absolute', right: '8em'}}  */}
        </Toolbar>
      </AppBar>
      <div className='sub-app-bar'>
        <a href='https://discord.gg/VC4Xpukyz8' target='_blank'><Typography style={{color: 'white', fontFamily: 'Ember'}} >Discord</Typography></a>
        <a href=''><Typography style={{color: 'white', fontFamily: 'Ember'}}>OpenSea</Typography></a>
        <a href='https://twitter.com/b0redjeffbezos' target='_blank'><Typography style={{color: 'white', fontFamily: 'Ember'}}>Twitter</Typography></a>
        {address !== '' ? <Typography style={{color: '#fe9900', fontFamily: 'Ember'}}>{address}</Typography> : <Typography style={{color: 'white', fontFamily: 'Ember', cursor: 'pointer'}} onClick={connectToWallet}>Connect Wallet</Typography>}
      </div>

      <div className='top-container' style={{width: '100%', position: 'relative', height: '44em', overflow: 'hidden', backgroundImage: "url(" + background_test + ")", backgroundSize: '100%', backgroundRepeat: 'no-repeat'}}>
        {/* <img src='images/background_test.png' style={{width: '100%', zIndex: '1'}}></img> */}

        <div className='image-container'>

          {/* <Grid container spacing="3" xs="12"> */}
            {/* Query all NFTs from collection and display them */}
              {/* <Grid item xs='3'> */}
                <div className='sub-container'>
                  <p className='img-header'>Popular deal</p>
                  <img src='images/og.png' style={{width: '97%'}}></img>
                </div>
              {/* </Grid> */}

              {/* <Grid item xs='3'> */}
                <div className='sub-container'>
                  <p className='img-header'>Spotlight deal</p>
                  <img src='images/vest.png' style={{width: '88%', marginLeft: '23px'}}></img>
                </div>
              {/* </Grid> */}

              {/* <Grid item xs='3'> */}
                <div className='sub-container'>
                  <p className='img-header'>Don't miss this deal</p>
                  <img src='images/sweater.png' style={{width: '91%', marginLeft: '16px'}}></img>
                </div>
              {/* </Grid> */}

              {/* <Grid item xs='3'> */}
                <div className='sub-container'>
                  <p className='img-header'>Looks rare</p>
                  <img className='image-four' src='images/blue_origin.png' style={{width: '89%', marginLeft: '18px'}}></img>
                </div>
              {/* </Grid> */}

          {/* </Grid> */}
        </div>
      </div>

      <div className='bottom-container' style={{backgroundColor: '#eaeded', overflow: 'hidden'}}>
        <img src='images/find_your_jeff.png' className='find-your-jeff'></img>
      </div>

      <img src='images/bottom_bar.png' style={{width: '100%', overflow: 'hidden'}}></img>
    </div>
  );
}

export default App;
